<template>
  <b-form class="form" id="frmReporte">
    <b-card no-body class="mb-3 card-custom">
      <div class="card-header border-0 py-5">
        <input
          v-model="titulo"
          type="text"
          class="card-title form-control form-control-lg px-0 font-weight-bold align-items-start flex-column"
          style="flex-grow: 1;width: auto;border:none;"
          placeholder="Título del reporte"
          required
        />
        <div class="card-toolbar ml-auto">
          <b-btn
            class="btn btn-secondary mr-3"
            @click="previewDimensionConfiguracion"
            ><i class="fa fa-eye"></i> Visualizar</b-btn
          >
          <b-btn
            class="btn btn-secondary mr-3"
            @click="downloadDimensionConfiguracion"
            ><i class="fa fa-file-excel"></i> Exportar</b-btn
          >

          <b-dd right variant="primary">
            <template #button-content>
              <i class="fa fa-save"></i> Guardar</template
            >
            <b-dd-item
              @click="saveDimensionConfiguracion(true)"
              v-if="reporteDimensionConfiguracionId"
              ><i class="fa fa-fw fa-save"></i> Actualizar reporte
              existente</b-dd-item
            >
            <b-dropdown-divider
              v-if="reporteDimensionConfiguracionId"
            ></b-dropdown-divider>
            <b-dd-item @click="saveDimensionConfiguracion(false)"
              ><i class="fa fa-fw fa-plus"></i> Como un nuevo reporte</b-dd-item
            >
          </b-dd>
        </div>
      </div>

      <!--<b-card-body> </b-card-body>
      <b-card-footer class="text-right">
        <div class="col-md-12">
          <router-link to="list" class="btn btn-default"><i class="fa fa-arrow-left"></i> Cancelar</router-link>
          <b-btn class="btn btn-primary" @click="saveUsuario"><i class="fa fa-save"></i> Guardar</b-btn>
        </div>
      </b-card-footer>-->
    </b-card>
    <div class="nav-tabs-top mb-4">
      <ul class="nav nav-tabs">
        <li
          class="nav-item"
          v-for="(hoja, hojaIndex) in configuracion.hojas"
          :key="hojaIndex"
        >
          <a
            class="nav-link"
            :class="[hoja == ui.hojaSeleccionada ? 'active' : '']"
            @click.prevent="ui.hojaSeleccionada = hoja"
            >{{ hoja.titulo || "Sin título" }}</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link bg-secondary text-white"
            href="#"
            @click.prevent="addHoja()"
            ><i class="fa fa-plus"></i
          ></a>
        </li>
      </ul>
      <div class="tab-content">
        <div
          class="tab-pane fade "
          :class="[hoja == ui.hojaSeleccionada ? 'show active' : '']"
          v-for="(hoja, hojaIndex) in configuracion.hojas"
          :key="hojaIndex"
        >
          <div class="card ">
            <div class="card-body">
              <div class="input-group">
                <input
                  type="text"
                  v-model="hoja.titulo"
                  class="form-control text-big font-weight-bold px-0 mr-2"
                  style="border:none"
                  placeholder="Título de hoja"
                  required
                  data-parsley-pattern="/^[a-zA-Z][a-zA-Z0-9()-_ ]*$/"
                />
                <span class="input-group-append">
                  <button
                    class="btn btn-default rounded"
                    type="button"
                    @click="deleteHoja(hoja)"
                  >
                    <i class="fa fa-times"></i>
                  </button>
                </span>
              </div>

              <hr />
              <h5><i class="fa fa-filter"></i> Filtros:</h5>

              <div
                class="card mb-3 border"
                v-for="(bloqueFiltro, indexBloqueFiltro) in hoja.bloqueFiltros"
                :key="indexBloqueFiltro"
              >
                <div class="card-header with-elements bg-light px-2">
                  <span class="card-header-title mr-2 font-weight-semibold"
                    >Bloque {{ indexBloqueFiltro + 1 }}</span
                  >
                  <div class="card-header-elements ml-md-auto">
                    <button
                      type="button"
                      class="btn icon-btn btn-sm btn-default"
                      @click="deleteBloqueFiltro(hoja, bloqueFiltro)"
                    >
                      <i class=" fa fa-times"></i>
                    </button>
                  </div>
                </div>
                <div class="card-body p-0">
                  <table class="table table-hover mb-0">
                    <thead>
                      <tr>
                        <th>Columna</th>
                        <th style="width:50px">Tipo</th>
                        <th style="width:200px">Comparación</th>
                        <th style="width:150px"></th>
                        <th style="width:150px"></th>
                        <th style="width:50px"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(filtro, indexFiltro) in bloqueFiltro.filtros"
                        :key="indexFiltro"
                      >
                        <td class="align-middle">
                          {{ filtro.nombreColumnaReporte }}
                        </td>
                        <td class="align-middle">
                          {{ operadoresTipo[filtro.tipoDato] }}
                        </td>
                        <td>
                          <select
                            class="form-control"
                            v-model="filtro.operadorSeleccionado"
                            required
                          >
                            <option :value="null">- Seleccionar -</option>
                            <option
                              :value="operador"
                              v-for="(operador, indexOperador) in operadores[
                                filtro.tipoDato
                              ]"
                              :key="indexOperador"
                              >{{ operador.nombre }}</option
                            >
                          </select>
                        </td>
                        <td>
                          <span v-if="filtro.operadorSeleccionado != null">
                            <input
                              v-if="
                                filtro.operadorSeleccionado.tipoValorA != null
                              "
                              v-model="filtro.valorA"
                              :type="filtro.operadorSeleccionado.tipoValorA"
                              class="form-control"
                              required
                            />
                          </span>
                        </td>
                        <td>
                          <span v-if="filtro.operadorSeleccionado != null">
                            <input
                              v-if="
                                filtro.operadorSeleccionado.tipoValorB != null
                              "
                              v-model="filtro.valorB"
                              :type="filtro.operadorSeleccionado.tipoValorB"
                              class="form-control"
                              required
                            />
                          </span>
                        </td>
                        <td class="align-middle">
                          <button
                            type="button"
                            class="btn icon-btn btn-sm btn-default"
                            @click="deleteFiltro(bloqueFiltro, filtro)"
                          >
                            <i class=" fa fa-times"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>
                          <div class="input-group">
                            <select
                              class="custom-select"
                              v-model="bloqueFiltro.columnaFiltroSeleccionado"
                            >
                              <option :value="null">- Seleccionar -</option>
                              <option
                                :value="columna"
                                v-for="columna in columnas"
                                :key="columna.reporteDimensionColumnaId"
                                >{{ columna.nombreColumnaReporte }}</option
                              >
                            </select>
                            <span class="input-group-append">
                              <button
                                class="btn btn-secondary"
                                type="button"
                                @click="addFiltro(bloqueFiltro)"
                              >
                                <i class="fa fa-plus"></i>
                              </button>
                            </span>
                          </div>
                        </td>
                        <td colspan="5"></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <button
                class="btn btn-sm btn-secondary"
                type="button"
                @click="addBloqueFiltro(hoja)"
              >
                <i class="fa fa-plus"></i> Agregar bloque
              </button>

              <hr />

              <h5><i class="fa fa-columns"></i> Columnas:</h5>

              <div role="group" class="input-group mb-3">
                <div class="input-group-prepend">
                  <div class="input-group-text border-0">
                    <i class="fa fa-search"></i>
                  </div>
                </div>
                <input
                  type="text"
                  v-model="hoja.filtroColumnas"
                  class="form-control border-0"
                  placeholder="Buscar por nombre de columna..."
                />
              </div>
              <draggable
                v-model="hoja.ordenColumnas"
                v-bind="{ animation: 150 }"
                tag="ul"
                class="sortable-column sortable-column-inline"
              >
                <li
                  v-for="item in hoja.ordenColumnas"
                  :key="item.reporteDimensionColumnaId"
                  :class="[
                    item.seleccionado == true
                      ? 'bg-secondary text-white'
                      : 'bg-light',
                    !hoja.filtroColumnas
                      ? ''
                      : item.nombreColumnaReporte
                          .toLowerCase()
                          .indexOf(hoja.filtroColumnas.toLowerCase()) != -1
                      ? 'sortable-column-filter-match'
                      : 'sortable-column-filter-no-match',
                  ]"
                  @click="doMarcarSeleccionColumna(hoja, item)"
                  style="cursor:pointer;"
                >
                  {{ item.nombreColumnaReporte }}
                </li>
              </draggable>

              <hr />
              <h5><i class="fa fa-sort-alpha-down"></i> Ordenamiento:</h5>

              <div class="card mb-3 border">
                <div class="card-body p-0">
                  <table class="table table-hover mb-0">
                    <thead>
                      <tr>
                        <th>Columna</th>
                        <th style="width:50px">Orden</th>
                        <th style="width:200px">Comparación</th>
                        <th style="width:50px"></th>
                      </tr>
                    </thead>

                    <draggable
                      v-model="hoja.ordenamientos"
                      v-bind="{ animation: 150 }"
                      tag="tbody"
                    >
                      <tr
                        v-for="(ordenamiento,
                        indexOrdenamiento) in hoja.ordenamientos"
                        :key="indexOrdenamiento"
                      >
                        <td class="align-middle">
                          {{ ordenamiento.nombreColumnaReporte }}
                        </td>
                        <td>{{ indexOrdenamiento + 1 }}</td>
                        <td>
                          <select
                            class="form-control"
                            v-model="ordenamiento.esDescendente"
                            required
                          >
                            <option :value="false">Ascendente</option>
                            <option :value="true">Descendente</option>
                          </select>
                        </td>
                        <td class="align-middle">
                          <button
                            type="button"
                            class="btn icon-btn btn-sm btn-default"
                            @click="deleteOrdenamiento(hoja, ordenamiento)"
                          >
                            <i class=" fa fa-times"></i>
                          </button>
                        </td>
                      </tr>
                    </draggable>

                    <!--
                  <tr v-for="(ordenamiento, indexOrdenamiento) in hoja.ordenamientos" :key="indexOrdenamiento">
                    <td class="align-middle">{{ ordenamiento.nombreColumnaReporte }}</td>
                    <td>
                      <select class="form-control" v-model="ordenamiento.esDescendente" required>
                        <option :value="false">Ascendente</option>
                        <option :value="true">Descendente</option>
                      </select>
                    </td>
                    <td class="align-middle">
                      <button type="button" class="btn icon-btn btn-sm btn-default" @click="deleteOrdenamiento(hoja, ordenamiento)">
                        <i class=" fa fa-times"></i>
                      </button>
                    </td>
                  </tr>-->

                    <tfoot>
                      <tr>
                        <td>
                          <div class="input-group">
                            <select
                              class="custom-select"
                              v-model="hoja.columnaOrdenamientoSeleccionado"
                            >
                              <option :value="null">- Seleccionar -</option>
                              <option
                                :value="columna"
                                v-for="columna in columnas"
                                :key="columna.reporteDimensionColumnaId"
                                >{{ columna.nombreColumnaReporte }}</option
                              >
                            </select>
                            <span class="input-group-append">
                              <button
                                class="btn btn-secondary"
                                type="button"
                                @click="addOrdenamiento(hoja)"
                              >
                                <i class="fa fa-plus"></i>
                              </button>
                            </span>
                          </div>
                        </td>
                        <td colspan="5"></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>

              <hr />
              <h5><i class="fa fa-list-ol"></i> Límites:</h5>

              Puede definir la cantidad máxima de filas a mostrar. Si no se
              registra un valor, se mostrarán todas las filas.

              <input
                v-model="hoja.limite"
                type="number"
                class="form-control"
                style="width:150px"
                placeholder="Sin límite"
                min="1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="preview-modal" size="xl" :title="titulo" hide-footer>
      <div v-if="ui.hojaPreviewSeleccionada">
        <div class="mb-3">
          <b-dd variant="primary">
            <template #button-content>
              <i class="fa fa-eye"></i> {{ ui.hojaPreviewSeleccionada.titulo }}
            </template>
            <b-dd-item
              v-for="(tabla, indexTabla) in preview.result.tablas"
              :key="indexTabla"
              @click="ui.hojaPreviewSeleccionada = tabla"
              >{{ tabla.titulo }}</b-dd-item
            >
          </b-dd>

          <button
            class="btn btn-secondary"
            id="btn-copy-preview"
            @click="
              copyToClipboard(
                'tbl-' + ui.hojaPreviewSeleccionada.indice,
                'btn-copy-preview'
              )
            "
          >
            <i class="fa fa-copy fa-fw"></i>
          </button>

          <button
            class="btn btn-default"
            v-if="ui.hojaPreviewComprimirCeldas"
            @click="ui.hojaPreviewComprimirCeldas = false"
          >
            <i class="fa fa-compress fa-fw"></i> Comprimido
          </button>

          <button
            class="btn btn-default"
            v-if="!ui.hojaPreviewComprimirCeldas"
            @click="ui.hojaPreviewComprimirCeldas = true"
          >
            <i class="fa fa-expand fa-fw"></i> Expandido
          </button>

          <span class="ml-2"
            ><b>{{ ui.hojaPreviewSeleccionada.filas.length }}</b> fila(s)</span
          >

          <span
            v-b-tooltip.hover
            :title="
              preview.segundosObtenerDatos.toFixed(0) +
                ' seg. de extracción y ' +
                preview.segundosConvertirDatos.toFixed(0) +
                ' seg. de transformación.'
            "
          >
            en {{ preview.segundosTotal.toFixed(0) }} seg.</span
          >
        </div>

        <div class="table-responsive mb-0" style="max-height:500px">
          <table
            class="table table-sm"
            :id="'tbl-' + ui.hojaPreviewSeleccionada.indice"
            :class="[
              ui.hojaPreviewComprimirCeldas ? 'table-cell-ellipsis' : '',
            ]"
          >
            <tr>
              <th
                class="text-center"
                v-for="(columna, index) in ui.hojaPreviewSeleccionada.columnas"
                :key="index"
              >
                <span>{{ columna }}</span>
              </th>
            </tr>
            <tr
              v-for="(fila, index) in ui.hojaPreviewSeleccionada.filas"
              :key="index"
            >
              <td
                class="text-center ellipsis"
                v-for="(valor, indexVal) in fila.valores"
                :key="indexVal"
              >
                <span>
                  {{ valor }}
                </span>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <!--<div slot="modal-footer" class="d-flex justify-content-between w-100">
        <div class="float-left"></div>
        <b-button variant="primary" class="float-right" @click="$bvModal.hide('preview-modal')">
          Cerrar
        </b-button>
      </div>-->
    </b-modal>

    <b-modal id="download-modal" size="md" :title="titulo" hide-footer>
      <div v-if="ui.hasDownloadResult">
        <div class="mb-3">
          <p>
            Reporte generado en {{ download.segundosTotal.toFixed(0) }} segundos
            ({{ download.segundosObtenerDatos.toFixed(0) }} de extracción y
            {{ download.segundosConvertirDatos.toFixed(0) }} de transformación).
          </p>
          <p>
            Utilice el siguiente enlace para descargar o compartir el reporte:
          </p>
          <div class="card p-2 mb-2 border bg-light">
            {{ filesUrl + download.reportUrl }}
          </div>

          <a
            class="btn btn-default"
            :href="`${filesUrl + download.reportUrl}`"
            target="_blank"
            ><i class="fa fa-download fa-fw"></i> Descargar reporte</a
          >
        </div>
      </div>
      <!--<div slot="modal-footer" class="d-flex justify-content-between w-100">
        <div class="float-left"></div>
        <b-button variant="primary" class="float-right" @click="$bvModal.hide('preview-modal')">
          Cerrar
        </b-button>
      </div>-->
    </b-modal>
  </b-form>
</template>

<style>
.table-cell-ellipsis th {
  min-width: 100px;
}

.sortable-column {
  margin: 0;
  padding: 0;
}
.sortable-column li {
  padding: 6px 10px;
  margin-bottom: 6px;
  list-style: none;
  border: 2px solid transparent;
}

.sortable-column .sortable-column-filter-match {
  border: 2px solid #eb0729;
}
.sortable-column .bg-secondary.sortable-column-filter-match {
  border: 2px solid #eb0729;
}

.sortable-column-inline li {
  margin-right: 6px;
  display: inline-block;
}
[dir="rtl"] .sortable-column-inline li {
  margin-right: 0;
  margin-left: 6px;
}
</style>

<script>
import Vue from "vue";
import $ from "jquery";
import "parsleyjs";
import draggable from "vuedraggable/src/vuedraggable";

import Swal from "sweetalert2";

/*intervalos de tiempo de Inactividad*/
import {
  INACTIVE_USER_TIME_THRESHOLD,
  USER_ACTIVITY_THROTTLER_TIME,
} from "@/helpers/constants.js";

export default {
  name: "edit-dimension",
  metaInfo: {
    title: "Editar Reporte",
  },
  components: {
    draggable,
  },
  data: () => ({
    reporteDimensionId: null,
    reporteDimensionConfiguracionId: null,
    titulo: "",

    columnas: [],
    columnasDict: {},
    configuracion: {
      hojas: [],
    },
    ui: {
      numHojas: 1,
      hojaSeleccionada: null,
      hojaPreviewSeleccionada: null,
      hojaPreviewComprimirCeldas: true,
      clearCopyTimeout: null,
      hasDownloadResult: false,
    },
    preview: {
      result: {
        tablas: [],
      },
      segundosObtenerDatos: null,
      segundosConvertirDatos: null,
      segundosTotal: null,
    },
    download: {
      reportUrl: null,
      segundosObtenerDatos: null,
      segundosConvertirDatos: null,
      segundosTotal: null,
    },
    blank: {
      hoja: {
        titulo: "",
        limite: null,
        bloqueFiltros: [],
        ordenamientos: [],
        ordenColumnas: [],
        columnaOrdenamientoSeleccionado: null,
      },
      bloqueFiltro: {
        columnaFiltroSeleccionado: null,
        filtros: [],
      },
      filtro: {
        reporteDimensionColumnaId: null,
        nombreColumnaReporte: "",
        nombreColumnaOrigen: "",
        tipoDato: "",
        operadorSeleccionado: null,
        valorA: null,
        valorB: null,
      },
      ordenamiento: {
        reporteDimensionColumnaId: null,
        nombreColumnaReporte: "",
        nombreColumnaOrigen: "",
        esDescendente: false,
      },
    },
    operadores: {
      INT: [
        {
          codigo: "GT",
          nombre: "Mayor a",
          tipoValorA: "number",
          tipoValorB: null,
        },
        {
          codigo: "GET",
          nombre: "Mayor o igual a",
          tipoValorA: "number",
          tipoValorB: null,
        },
        {
          codigo: "LT",
          nombre: "Menor a",
          tipoValorA: "number",
          tipoValorB: null,
        },
        {
          codigo: "LET",
          nombre: "Menor o igual a",
          tipoValorA: "number",
          tipoValorB: null,
        },
        {
          codigo: "EQ",
          nombre: "Igual a",
          tipoValorA: "number",
          tipoValorB: null,
        },
        {
          codigo: "NOT_EQ",
          nombre: "Diferente de",
          tipoValorA: "number",
          tipoValorB: null,
        },
        {
          codigo: "IN_RAN",
          nombre: "Entre",
          tipoValorA: "number",
          tipoValorB: "number",
        },
        {
          codigo: "OUT_RAN",
          nombre: "Fuera del rango",
          tipoValorA: "number",
          tipoValorB: "number",
        },
        {
          codigo: "IS_NOT_NULL",
          nombre: "Tiene valor",
          tipoValorA: null,
          tipoValorB: null,
        },
        {
          codigo: "IS_NULL",
          nombre: "No tiene valor",
          tipoValorA: null,
          tipoValorB: null,
        },
      ],
      DEC: [
        {
          codigo: "GT",
          nombre: "Mayor a",
          tipoValorA: "number",
          tipoValorB: null,
        },
        {
          codigo: "GET",
          nombre: "Mayor o igual a",
          tipoValorA: "number",
          tipoValorB: null,
        },
        {
          codigo: "LT",
          nombre: "Menor a",
          tipoValorA: "number",
          tipoValorB: null,
        },
        {
          codigo: "LET",
          nombre: "Menor o igual a",
          tipoValorA: "number",
          tipoValorB: null,
        },
        {
          codigo: "EQ",
          nombre: "Igual a",
          tipoValorA: "number",
          tipoValorB: null,
        },
        {
          codigo: "NOT_EQ",
          nombre: "Diferente de",
          tipoValorA: "number",
          tipoValorB: null,
        },
        {
          codigo: "IN_RAN",
          nombre: "Entre",
          tipoValorA: "number",
          tipoValorB: "number",
        },
        {
          codigo: "OUT_RAN",
          nombre: "Fuera del rango",
          tipoValorA: "number",
          tipoValorB: "number",
        },
        {
          codigo: "IS_NOT_NULL",
          nombre: "Tiene valor",
          tipoValorA: null,
          tipoValorB: null,
        },
        {
          codigo: "IS_NULL",
          nombre: "No tiene valor",
          tipoValorA: null,
          tipoValorB: null,
        },
      ],
      STR: [
        {
          codigo: "EQ",
          nombre: "Igual a",
          tipoValorA: "text",
          tipoValorB: null,
        },
        {
          codigo: "NOT_EQ",
          nombre: "Diferente de",
          tipoValorA: "text",
          tipoValorB: null,
        },
        {
          codigo: "LIKE",
          nombre: "Contiene",
          tipoValorA: "text",
          tipoValorB: null,
        },
        {
          codigo: "NOT_LIKE",
          nombre: "No contiene",
          tipoValorA: "text",
          tipoValorB: null,
        },
        {
          codigo: "START",
          nombre: "Empieza con",
          tipoValorA: "text",
          tipoValorB: null,
        },
        {
          codigo: "NOT_START",
          nombre: "No empieza con",
          tipoValorA: "text",
          tipoValorB: null,
        },
        {
          codigo: "END",
          nombre: "Termina con",
          tipoValorA: "text",
          tipoValorB: null,
        },
        {
          codigo: "NOT_END",
          nombre: "No termina con",
          tipoValorA: "text",
          tipoValorB: null,
        },
        {
          codigo: "IS_NOT_NULL",
          nombre: "Tiene valor",
          tipoValorA: null,
          tipoValorB: null,
        },
        {
          codigo: "IS_NULL",
          nombre: "No tiene valor",
          tipoValorA: null,
          tipoValorB: null,
        },
      ],
      DATE: [
        {
          codigo: "GET",
          nombre: "Mayor o igual a",
          tipoValorA: "date",
          tipoValorB: null,
        },
        {
          codigo: "LET",
          nombre: "Menor o igual a",
          tipoValorA: "date",
          tipoValorB: null,
        },
        {
          codigo: "EQ",
          nombre: "Igual a",
          tipoValorA: "date",
          tipoValorB: null,
        },
        {
          codigo: "NOT_EQ",
          nombre: "Diferente de",
          tipoValorA: "date",
          tipoValorB: null,
        },
        {
          codigo: "IN_RAN",
          nombre: "Entre",
          tipoValorA: "date",
          tipoValorB: "date",
        },
        {
          codigo: "OUT_RAN",
          nombre: "Fuera del rango",
          tipoValorA: "date",
          tipoValorB: "date",
        },
        {
          codigo: "IS_NOT_NULL",
          nombre: "Tiene valor",
          tipoValorA: null,
          tipoValorB: null,
        },
        {
          codigo: "IS_NULL",
          nombre: "No tiene valor",
          tipoValorA: null,
          tipoValorB: null,
        },
      ],
      BOOL: [
        {
          codigo: "IS_TRUE",
          nombre: "Es verdadero",
          tipoValorA: null,
          tipoValorB: null,
        },
        {
          codigo: "IS_FALSE",
          nombre: "Es falso",
          tipoValorA: null,
          tipoValorB: null,
        },
        {
          codigo: "IS_NOT_NULL",
          nombre: "Tiene valor",
          tipoValorA: null,
          tipoValorB: null,
        },
        {
          codigo: "IS_NULL",
          nombre: "No tiene valor",
          tipoValorA: null,
          tipoValorB: null,
        },
      ],
    },
    operadoresTipo: {
      INT: "Entero",
      DEC: "Decimal",
      STR: "Texto",
      DATE: "Fecha",
      BOOL: "V/F",
    },
    /*variables de inactividad*/
    isInactive: false,
    userActivityThrottlerTimeout: null,
    userActivityTimeout: null,
  }),
  methods: {
    addHoja: function() {
      var blankHoja = JSON.parse(JSON.stringify(this.blank.hoja));
      this.ui.numHojas += 1;
      blankHoja.titulo = "Hoja " + this.ui.numHojas;

      blankHoja.ordenColumnas = JSON.parse(JSON.stringify(this.columnas));
      this.ui.hojaSeleccionada = blankHoja;
      this.configuracion.hojas.push(blankHoja);
    },
    copyToClipboard: async function(containerid, elementReplaceIcon) {
      let el = document.getElementById(containerid);
      var body = document.body,
        range,
        sel;
      if (document.createRange && window.getSelection) {
        range = document.createRange();
        sel = window.getSelection();
        sel.removeAllRanges();
        try {
          range.selectNodeContents(el);
          sel.addRange(range);
        } catch (e) {
          range.selectNode(el);
          sel.addRange(range);
        }
        document.execCommand("copy");
      } else if (body.createTextRange) {
        range = body.createTextRange();
        range.moveToElementText(el);
        range.select();
        range.execCommand("Copy");
      }

      if (!elementReplaceIcon) {
        await this.sweetAlert("Los datos han sido copiados.");
      } else {
        if (this.ui.clearCopyTimeout) clearTimeout(this.ui.clearCopyTimeout);

        var btnCopiar = document.getElementById(elementReplaceIcon);
        btnCopiar.innerHTML = '<i class="fa fa-check fa-fw"></i>';

        this.ui.clearCopyTimeout = setTimeout(function() {
          var btnCopiar = document.getElementById(elementReplaceIcon);
          btnCopiar.innerHTML = '<i class="fa fa-copy fa-fw"></i>';
        }, 2000);
      }
    },
    deleteHoja: async function(hoja) {
      if (await this.sweetConfirm("¿Desea eliminar esta hoja?")) {
        this.configuracion.hojas = this.removeArrayItem(
          this.configuracion.hojas,
          hoja
        );
        if (this.configuracion.hojas.length == 0) this.addHoja();
        else this.ui.hojaSeleccionada = this.configuracion.hojas[0];
      }
    },
    addBloqueFiltro: function(hoja) {
      var blankBloqueFiltro = JSON.parse(
        JSON.stringify(this.blank.bloqueFiltro)
      );
      hoja.bloqueFiltros.push(blankBloqueFiltro);
    },
    deleteBloqueFiltro: async function(hoja, bloqueFiltro) {
      if (await this.sweetConfirm("¿Desea eliminar este bloque?")) {
        hoja.bloqueFiltros = this.removeArrayItem(
          hoja.bloqueFiltros,
          bloqueFiltro
        );
      }
    },
    addFiltro: function(bloqueFiltro) {
      if (bloqueFiltro.columnaFiltroSeleccionado == null) {
        alert("Debe seleccionar una columna para agregar el filtro.");
        return;
      }

      var blankFiltro = JSON.parse(JSON.stringify(this.blank.filtro));

      blankFiltro.reporteDimensionColumnaId =
        bloqueFiltro.columnaFiltroSeleccionado.reporteDimensionColumnaId;
      blankFiltro.nombreColumnaReporte =
        bloqueFiltro.columnaFiltroSeleccionado.nombreColumnaReporte;
      blankFiltro.nombreColumnaOrigen =
        bloqueFiltro.columnaFiltroSeleccionado.nombreColumnaOrigen;
      blankFiltro.tipoDato = bloqueFiltro.columnaFiltroSeleccionado.tipoDato;

      bloqueFiltro.columnaFiltroSeleccionado = null;
      bloqueFiltro.filtros.push(blankFiltro);
    },
    deleteFiltro: async function(bloqueFiltro, filtro) {
      if (await this.sweetConfirm("¿Desea eliminar este filtro?")) {
        bloqueFiltro.filtros = this.removeArrayItem(
          bloqueFiltro.filtros,
          filtro
        );
      }
    },
    addOrdenamiento: function(hoja) {
      if (hoja.columnaOrdenamientoSeleccionado == null) {
        alert("Debe seleccionar una columna para agregar el ordenamiento.");
        return;
      }

      var blankOrdenamiento = JSON.parse(
        JSON.stringify(this.blank.ordenamiento)
      );

      blankOrdenamiento.reporteDimensionColumnaId =
        hoja.columnaOrdenamientoSeleccionado.reporteDimensionColumnaId;
      blankOrdenamiento.nombreColumnaReporte =
        hoja.columnaOrdenamientoSeleccionado.nombreColumnaReporte;
      blankOrdenamiento.nombreColumnaOrigen =
        hoja.columnaOrdenamientoSeleccionado.nombreColumnaOrigen;
      blankOrdenamiento.esDescendente = false;

      hoja.columnaOrdenamientoSeleccionado = null;
      hoja.ordenamientos.push(blankOrdenamiento);
    },
    deleteOrdenamiento: async function(hoja, ordenamiento) {
      if (await this.sweetConfirm("¿Desea eliminar este ordenamiento?")) {
        hoja.ordenamientos = this.removeArrayItem(
          hoja.ordenamientos,
          ordenamiento
        );
      }
    },
    doMarcarSeleccionColumna: function(hoja, item) {
      if (!item.seleccionado) item.seleccionado = true;
      else item.seleccionado = false;
      hoja.ordenColumnas = hoja.ordenColumnas.slice();
    },
    fetchDimensionColumnas: async function() {
      debugger;
      var model = {
        ReporteDimensionId: this.reporteDimensionId,
      };

      await this.$http
        .get(this.apiRoute("ReportDimension", "ListColumnasDimension"), {
          params: model,
        })
        .then(function(response) {
          if (!response.body.error) {
            this.columnas = response.body.data;
            this.columnasDict = this.toDictionary(
              response.body.data,
              "reporteDimensionColumnaId"
            );
          }
        });
    },
    fetchDimensionConfiguracion: async function() {
      var model = {
        ReporteDimensionConfiguracionId: this.reporteDimensionConfiguracionId,
      };

      var configLoad = null;

      await this.$http
        .get(this.apiRoute("ReportDimension", "GetDimensionConfiguracion"), {
          params: model,
        })
        .then(function(response) {
          if (response.body.error) {
            this.procesarRespuestaGuardarDefecto(response);
          }
          if (!response.body.error) {
            if (
              response.body.data.reporteDimensionId != this.reporteDimensionId
            ) {
              alert(
                "Hay inconsistencia en la dimensión del reporte. Vuelva a ingresar a través del listado de dimensiones."
              );
            }

            this.titulo = response.body.data.titulo;
            configLoad = JSON.parse(response.body.data.configuracionStr);
          }
        });

      //Convertir Load a Estructura requerida.

      for (let h = 0; h < configLoad.hojas.length; h++) {
        let hojaIter = configLoad.hojas[h];
        hojaIter.columnaOrdenamientoSeleccionado = null;
        /*let hojaSave = {
          titulo: hojaIter.titulo,
          bloqueFiltros: [],
          ordenColumnas: [],
          ordenamientos: [],
          limite: hojaIter.limite,
        };*/

        for (let bf = 0; bf < hojaIter.bloqueFiltros.length; bf++) {
          let bloqueFiltroIter = hojaIter.bloqueFiltros[bf];
          bloqueFiltroIter.columnaFiltroSeleccionado = null;

          for (let f = 0; f < bloqueFiltroIter.filtros.length; f++) {
            let filtroIter = bloqueFiltroIter.filtros[f];

            filtroIter.operadorSeleccionado = this.operadores[
              filtroIter.tipoDato
            ].find((x) => x.codigo == filtroIter.operador);

            /*let filtroSave = {
              reporteDimensionColumnaId: filtroIter.reporteDimensionColumnaId,
              nombreColumnaReporte: filtroIter.nombreColumnaReporte,
              nombreColumnaOrigen: filtroIter.nombreColumnaOrigen,
              tipoDato: filtroIter.tipoDato,
              operador: filtroIter.operadorSeleccionado.codigo,
              valorA: filtroIter.operadorSeleccionado.tipoValorA != null ? filtroIter.valorA : null,
              valorB: filtroIter.operadorSeleccionado.tipoValorB != null ? filtroIter.valorB : null,
            };*/
          }
        }

        for (let c = 0; c < hojaIter.ordenColumnas.length; c++) {
          let ordenColumnaIter = hojaIter.ordenColumnas[c];

          /*let ordenColumnaSave = {
            reporteDimensionColumnaId: ordenColumnaIter.reporteDimensionColumnaId,
            nombreColumnaReporte: ordenColumnaIter.nombreColumnaReporte,
            nombreColumnaOrigen: ordenColumnaIter.nombreColumnaOrigen,
            seleccionado: ordenColumnaIter.seleccionado == true,
          };*/
        }

        for (let o = 0; o < hojaIter.ordenamientos.length; o++) {
          let ordenamientoIter = hojaIter.ordenamientos[o];

          /*let ordenamientoSave = {
            reporteDimensionColumnaId: ordenamientoIter.reporteDimensionColumnaId,
            nombreColumnaReporte: ordenamientoIter.nombreColumnaReporte,
            nombreColumnaOrigen: ordenamientoIter.nombreColumnaOrigen,
            esDescendente: ordenamientoIter.esDescendente == true,
          };*/
        }
      }

      this.configuracion = configLoad;

      this.ui.hojaSeleccionada = this.configuracion.hojas[0];
    },

    generateSaveConfiguration: function() {
      var configSave = {
        reporteDimensionId: this.reporteDimensionId,
        titulo: this.titulo,
        hojas: [],
      };

      for (let h = 0; h < this.configuracion.hojas.length; h++) {
        let hojaIter = this.configuracion.hojas[h];
        let hojaSave = {
          titulo: hojaIter.titulo,
          bloqueFiltros: [],
          ordenColumnas: [],
          ordenamientos: [],
          limite: hojaIter.limite,
        };

        configSave.hojas.push(hojaSave);

        for (let bf = 0; bf < hojaIter.bloqueFiltros.length; bf++) {
          let bloqueFiltroIter = hojaIter.bloqueFiltros[bf];

          let bloqueFiltroSave = {
            filtros: [],
          };

          hojaSave.bloqueFiltros.push(bloqueFiltroSave);

          for (let f = 0; f < bloqueFiltroIter.filtros.length; f++) {
            let filtroIter = bloqueFiltroIter.filtros[f];

            let filtroSave = {
              reporteDimensionColumnaId: filtroIter.reporteDimensionColumnaId,
              nombreColumnaReporte: filtroIter.nombreColumnaReporte,
              nombreColumnaOrigen: filtroIter.nombreColumnaOrigen,
              tipoDato: filtroIter.tipoDato,
              operador: filtroIter.operadorSeleccionado.codigo,
              valorA:
                filtroIter.operadorSeleccionado.tipoValorA != null
                  ? filtroIter.valorA
                  : null,
              valorB:
                filtroIter.operadorSeleccionado.tipoValorB != null
                  ? filtroIter.valorB
                  : null,
            };

            bloqueFiltroSave.filtros.push(filtroSave);
          }
        }

        for (let c = 0; c < hojaIter.ordenColumnas.length; c++) {
          let ordenColumnaIter = hojaIter.ordenColumnas[c];

          let ordenColumnaSave = {
            reporteDimensionColumnaId:
              ordenColumnaIter.reporteDimensionColumnaId,
            nombreColumnaReporte: ordenColumnaIter.nombreColumnaReporte,
            nombreColumnaOrigen: ordenColumnaIter.nombreColumnaOrigen,
            seleccionado: ordenColumnaIter.seleccionado == true,
          };

          hojaSave.ordenColumnas.push(ordenColumnaSave);
        }

        for (let o = 0; o < hojaIter.ordenamientos.length; o++) {
          let ordenamientoIter = hojaIter.ordenamientos[o];

          let ordenamientoSave = {
            reporteDimensionColumnaId:
              ordenamientoIter.reporteDimensionColumnaId,
            nombreColumnaReporte: ordenamientoIter.nombreColumnaReporte,
            nombreColumnaOrigen: ordenamientoIter.nombreColumnaOrigen,
            esDescendente: ordenamientoIter.esDescendente == true,
          };

          hojaSave.ordenamientos.push(ordenamientoSave);
        }
      }
      return configSave;
    },

    previewDimensionConfiguracion: async function() {
      var isValid = $("#frmReporte")
        .parsley()
        .validate();

      if (!isValid) {
        await this.sweetAlert(
          "Hay datos pendientes o incorrectos. Revise cada hoja e intente nuevamente."
        );
        return;
      }
      this.showLoading();
      var model = this.generateSaveConfiguration();
      this.ui.hojaPreviewSeleccionada = null;
      this.$http
        .post(
          this.apiRoute("ReportDimension", "GeneratePreviewFromConfiguracion"),
          model
        )
        .then(function(response) {
          if (response.body.error) {
            this.procesarRespuestaGuardarDefecto(response);
          } else {
            this.preview = response.body.data;
            this.ui.hojaPreviewSeleccionada = this.preview.result.tablas[0];
            this.$bvModal.show("preview-modal");
            this.showLoading(false);
          }
        });
    },

    downloadDimensionConfiguracion: async function() {
      var isValid = $("#frmReporte")
        .parsley()
        .validate();

      if (!isValid) {
        await this.sweetAlert(
          "Hay datos pendientes o incorrectos. Revise cada hoja e intente nuevamente."
        );
        return;
      }
      this.showLoading();
      var model = this.generateSaveConfiguration();
      this.ui.hasDownloadResult = false;
      this.$http
        .post(
          this.apiRoute("ReportDimension", "GenerateReportFromConfiguracion"),
          model
        )
        .then(function(response) {
          if (response.body.error) {
            this.procesarRespuestaGuardarDefecto(response);
          } else {
            this.download = response.body.data;
            this.ui.hasDownloadResult = true;
            this.$bvModal.show("download-modal");
            this.showLoading(false);
          }
        });
    },
    saveDimensionConfiguracion: async function(reemplazarAnterior) {
      var isValid = $("#frmReporte")
        .parsley()
        .validate();

      if (!isValid) {
        await this.sweetAlert(
          "Hay datos pendientes o incorrectos. Revise cada hoja e intente nuevamente."
        );
        return;
      }

      if (this.reporteDimensionConfiguracionId && !reemplazarAnterior) {
        if (
          !(await this.sweetConfirm(
            "Al guardar, creará un reporte nuevo. El reporte anterior estará disponible pero no será actualizado. ¿Desea continuar?"
          ))
        ) {
          return;
        }
      }

      if (reemplazarAnterior) {
        if (
          !(await this.sweetConfirm(
            "Al guardar, reemplazará la configuración anterior. ¿Desea continuar?"
          ))
        ) {
          return;
        }
      }

      var configSave = this.generateSaveConfiguration();

      var model = {
        ReporteDimensionConfiguracionId: reemplazarAnterior
          ? this.reporteDimensionConfiguracionId
          : null,
        ReporteDimensionId: this.reporteDimensionId,
        Titulo: this.titulo,
        ConfiguracionStr: JSON.stringify(configSave),
      };

      this.showLoading();

      var vm = this;

      this.$http
        .post(
          this.apiRoute("ReportDimension", "SaveDimensionConfiguracion"),
          model
        )
        .then(function(response) {
          this.procesarRespuestaGuardarDefecto(response, null, function() {
            vm.reporteDimensionConfiguracionId =
              response.body.data.reporteDimensionConfiguracionId;
            vm.reporteDimensionId = response.body.data.reporteDimensionId;

            vm.$router.replace({
              name: "reports-dimension-edit",
              params: {
                reporteDimensionId: vm.reporteDimensionId,
                reporteDimensionConfiguracionId:
                  vm.reporteDimensionConfiguracionId,
              },
            });
          });
        });
    },
    /*Metodos para verificar la inactividad del usuario*/
    activateActivityTracker: function() {
      window.addEventListener("mousedown", this.userActivityThrottler);
      window.addEventListener("mousemove", this.userActivityThrottler);
      window.addEventListener("scroll", this.userActivityThrottler);
      window.addEventListener("keydown", this.userActivityThrottler);
      window.addEventListener("resize", this.userActivityThrottler);
      window.addEventListener("touchstart", this.userActivityThrottler); //para dispositivos moviles
    },
    deactivateActivityTracker: function() {
      window.removeEventListener("mousedown", this.userActivityThrottler);
      window.removeEventListener("mousemove", this.userActivityThrottler);
      window.removeEventListener("scroll", this.userActivityThrottler);
      window.removeEventListener("keydown", this.userActivityThrottler);
      window.removeEventListener("resize", this.userActivityThrottler);
      window.removeEventListener("touchstart", this.userActivityThrottler); //para dispositivos moviles
    },
    resetUserActivityTimeout: function() {
      clearTimeout(this.userActivityTimeout);

      this.userActivityTimeout = setTimeout(() => {
        this.userActivityThrottler();
        this.inactiveUserAction();
      }, INACTIVE_USER_TIME_THRESHOLD); /*=> INACTIVE_USER_TIME_THRESHOLD */
    },
    userActivityThrottler: function() {
      if (this.isInactive) {
        this.isInactive = false;
      }

      if (!this.userActivityThrottlerTimeout) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout();
          clearTimeout(this.userActivityThrottlerTimeout);
          this.userActivityThrottlerTimeout = null;
        }, USER_ACTIVITY_THROTTLER_TIME); /*=> USER_ACTIVITY_THROTTLER_TIME*/
      }
    },
    inactiveUserAction: function() {
      this.isInactive = true;

      Swal.fire({
        icon: "warning",
        title: "Sesión Expirada",
        text: "Su sesión ha expirado. Por favor vuelva a iniciar sesión.",
        confirmButtonText: `Iniciar Sesión`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.deactivateActivityTracker();
          clearTimeout(this.userActivityTimeout);
          clearTimeout(this.userActivityThrottlerTimeout);
          this.$router.push({ name: "login" });
        }
      });
    },
  },
  watch: {},
  mounted: async function() {
    this.initParsley();
    debugger;
    this.reporteDimensionId = this.$route.params.reporteDimensionId;
    this.reporteDimensionConfiguracionId = this.$route.params.reporteDimensionConfiguracionId;
    this.showLoading();
    await this.fetchDimensionColumnas();

    if (this.reporteDimensionConfiguracionId) {
      await this.fetchDimensionConfiguracion();
    } else {
      this.addHoja();
    }

    this.activateActivityTracker();

    this.showLoading(false);
  },
};
</script>
